<template>
  <ScNormalPageLayout headingPart1="User" headingPart2="details">
    <div class="container my-5">
      <div class="col-10 offset-1 mb-3">
        <div class="m-3 row g-0 justify-content-between align-items-center">
          <div class="col-auto">
            <h4 v-if="$route.params.groupId">
              <router-link :to="{ name: 'user-group-user-list',
                                  params: { workshopId: mainMxCurrentWorkshopId, groupId: $route.params.groupId }}">
                <ScIcon name="angleLeft" class="me-2"/>Group</router-link>
            </h4>
            <h4 v-else >
              <router-link :to="{ name: 'user-list',
                                  params: { workshopId: mainMxCurrentWorkshopId }}">
                <ScIcon name="angleLeft" class="me-2"/>Users</router-link>
            </h4>
          </div>
          <div class="col-auto">
            <h2 v-if="!isUserLoading">
              <span class="me-2">
                <ScIcon v-if="loadedUser.roleCode === 'r:viewer'" name="userFW"/>
                <ScIcon v-if="loadedUser.roleCode === 'r:editor'" name="userNinjaFW"/>
                <ScIcon v-if="loadedUser.roleCode === 'r:manager'" name="userAstronautFW"/>
                <ScIcon v-if="loadedUser.roleCode === 'r:reporter'" name="userBountyHunterFW"/>
                <ScIcon v-if="loadedUser.roleCode === 'r:admin'" name="userCowboyFW"/>
              </span>
              {{loadedUser.displayableName}}
            </h2>
          </div>
          <div class="col-auto"></div>
        </div>
      </div>

      <div v-if="invalidUser" class="row justify-content-center">
        <div>This user has been removed from this workshop.</div>
      </div>

      <div v-else class="row justify-content-center">
        <div class="col-10">
          <div v-if="isUserLoading" class="text-center my-5 mx-auto">
            <ScIcon name="spinnerFW" class="text-muted"/>
          </div>
          <template v-else >
            <UserDetails :user="loadedUser" @user-details-changed="updateUserDetails"></UserDetails>
            <UserWorkspaceAttributes v-if="Object.keys(this.loadedUser).length > 0"
                                     :userId="userId"
                                     :canEditUser="loadedUser.canEditUser"
                                     :wsRef="loadedUser.userWorkspaceRef"></UserWorkspaceAttributes>
          </template>
        </div>
        <div class="col-10" v-if="displayTagSharingToggle">
          <div class="row">
            <div class="col-1 px-0 pt-1">
              <ScToggleOnOff
                  :loading="isUserLoading"
                  :active="loadedUserTagSharingStatus"
                  @sc-toggle="toggleTagSharing"></ScToggleOnOff>
            </div>
            <div class="col-11">
              <h4>Enable Tag Sharing</h4>
            </div>
          </div>
        </div>
        <div v-if="!isUserLoading" class="col-10">
          <UserRoleDetails :roleCode="loadedUser.roleCode"
                           :canEditUser="loadedUser.canEditUser"
                           @change-role="changeRole"></UserRoleDetails>
          <UserGroupDetails :user="loadedUser"></UserGroupDetails>
        </div>

        <div v-if="!isUserLoading" class="col-10" >
          <div class="border-top p-3 mb-5">
            <h3>Presentation Access</h3>
            <div v-if="isAccessLoading" class="text-center my-5 text-muted">
              <ScIcon name="spinnerFW" class="text-muted"/>
            </div>
            <div v-else-if="loadedScs.length === 0" class="d-flex mb-4 text-muted">
              <em v-if="loadedUser.roleCode === 'r:viewer'">
                  This user doesn't have access to any presentations
              </em>
              <em v-if="loadedUser.roleCode === 'r:editor'">
                This user can't currently edit any presentations (but, as an Editor, can still view all
                published presentations by default.)
              </em>
            </div>
            <template v-else>
              <span class="d-flex mb-3">
                <span v-if="loadedUser.roleCode === 'r:viewer'">
                  This user can view the following published presentations:
                </span>
                <span v-if="loadedUser.roleCode === 'r:editor'">
                  This user is an Editor, so can view all published presentations by default. They can edit the following presentations:
                </span>
                <span v-if="loadedUser.roleCode === 'r:manager'">
                  This user is a Manager, so can view all published presentations by default (but cannot edit). To limit
                  their view access, you can change their 'Role' in the panel further up this page.
                </span>
                <span v-if="loadedUser.roleCode === 'r:reporter' && !isLoadedUserReporterWithViewerAccess">
                  This user is a Reporter, so can view all published presentations by default (but cannot edit). To limit
                  their view access, you can change their 'Role' in the panel further up this page.
                </span>
                <span v-if="loadedUser.roleCode === 'r:reporter' && isLoadedUserReporterWithViewerAccess">
                  This user can view the following published presentations:
                </span>
                <span v-if="loadedUser.roleCode === 'r:admin'">
                  This user is an Admin, so can view and edit all presentations by default. To limit
                  their access, you can change their 'Role' in the panel further up this page.
                </span>
              </span>

              <template v-if="loadedUser.roleCode === 'r:viewer' || loadedUser.roleCode === 'r:editor' || isLoadedUserReporterWithViewerAccess">
                <div class="row g-0 py-3 border-top" v-for="sc in loadedScs" :key="'sc' + sc.id">
                  <div class="col-auto my-auto" style="width: 64px;">
                    <img v-if="sc.thumbnail" :src="sc.thumbnail" alt="Thumbnail" loading="lazy"
                         class="d-block mx-auto" :style="thumbDimsStyleObject(sc.thumbnail)"/>
                  </div>
                  <div class="col my-auto">
                    {{ sc.title }}
                  </div>
                  <div class="col-2 px-3 my-auto text-muted">
                    <span v-if="loadedUser.roleCode === 'r:viewer'">Can view</span>
                    <span v-if="loadedUser.roleCode === 'r:editor'">Can edit</span>
                  </div>
                  <div class="col-3 my-auto text-end">
                    <a v-if="mainMxUwCanPerform('a:list_pres_for_edit')"  href="#"
                       @click.prevent="openManageAccessModal(sc.id)" class="btn btn-link">
                      <ScIcon name="users"/> Manage Access</a>
                  </div>
                </div>
              </template>
            </template>
          </div>

        </div>
      </div>
    </div>
    <ManageAccessModal></ManageAccessModal>
  </ScNormalPageLayout>
</template>

<script>
    import $ from "jquery";
    import ScNormalPageLayout from "../global/ScNormalPageLayout.vue";
    import ScNotification from "../../shared/common/ScNotification.vue";
    import ScIcon from  "../../shared/common/ScIcon.vue";
    import ScCommonUtil from "../../shared/common/ScCommonUtil";
    import MainAppMixin from "../MainAppMixin";
    import ManageAccessModal from "../presentation/manage-access/ManageAccessModal.vue";

    import UserDetails from "./UserDetails.vue";
    import UserGroupDetails from "./UserGroupDetails.vue";
    import UserRoleDetails from "./UserRoleDetails.vue";
    import UserWorkspaceAttributes from "./UserWorkspaceAttributes.vue";
    import ScToggleOnOff from "../../shared/common/ScToggleOnOff.vue";

    export default {
      name: "UserView",
      mixins: [ MainAppMixin ],
      components: {
        ScToggleOnOff, ManageAccessModal, ScNormalPageLayout, ScIcon,
        UserDetails, UserGroupDetails, UserRoleDetails, UserWorkspaceAttributes },
      data () {
        return {
          userId: 0,
          loadedUser: {},
          loadedScs: [],
          isUserLoading: false,
          roleCode: 'r:admin',
          thumbnail: 'test',
          title: 'testTitle',
          invalidUser: false,
          isAccessLoading: false,

          displayTagSharingToggle: false,
          loadedUserTagSharingStatus: true,
          reporterViewerAccess: false,
        };
      },
      mounted () {
        this.userId = this.$route.params.userId;
        document.documentElement.scrollTop = 0;

        this.loadUser();
        this.getTagSharingToggleVisibility();
      },
      watch: {
        loadedUser (newValue, oldValue) {
          if (newValue.roleCode && newValue.roleCode !== oldValue?.roleCode) {
            this.determineReporterViewerAccess()
          }
        }
      },
      computed: {
        isLoadedUserReporterWithViewerAccess () {
          return this.loadedUser.roleCode === 'r:reporter' && this.reporterViewerAccess === true;
        }
      },
      methods: {
        toggleTagSharing() {
          const toState = !this.loadedUserTagSharingStatus;
          const func = (toState === true) ? 'ajax_allow_user_tag_sharing' : 'ajax_disable_user_tag_sharing'
          this.isAccessLoading = true;
          $.ajax({
            type: 'POST', url: '/main/users/' + func,
            data: { workspace_id: this.mainMxCurrentWorkshopId, user_id: this.userId }
          }).done((data)=> {
            if (data.status !== undefined) {
              this.loadedUserTagSharingStatus = toState;
            }
            this.isAccessLoading = false;
          }).fail(()=> {
            ScNotification.growlErrMsg('Error retrieving settings');
            this.isAccessLoading = false;
          });
        },
        getTagSharingToggleVisibility () {
          if (this.roleCode !== 'r:admin') {
            return;
          }
          this.isUserLoading = true;
          $.ajax({
            type: 'GET', url: '/main/users/ajax_determine_showcase_display_tag_sharing_toggle',
            data: { workspace_id: this.mainMxCurrentWorkshopId }
          }).done((data)=> {
            if (data.display !== undefined) {
              this.displayTagSharingToggle = data.display;
              this.getUserTagSharingStatus()
            }
            this.isUserLoading = false;
          }).fail(()=> {
            ScNotification.growlErrMsg('Error retrieving settings');
            this.isUserLoading = false;
          });
        },
        getUserTagSharingStatus () {
          this.isUserLoading = true;
          $.ajax({
            type: 'GET', url: '/main/users/ajax_determine_user_allow_tag_sharing',
            data: { workspace_id: this.mainMxCurrentWorkshopId, user_id: this.userId }
          }).done((data)=> {
            if (data.allowed !== undefined) {
              this.loadedUserTagSharingStatus = data.allowed;
            }
            this.isUserLoading = false;
          }).fail(()=> {
            ScNotification.growlErrMsg('Error retrieving settings');
            this.isUserLoading = false;
          });
        },
        changeRole (roleCode) {
          //console.log('changeRole');
          this.saveUserRole(this.loadedUser, roleCode);
        },
        openManageAccessModal (scId) {
          ManageAccessModal.modal({ presentation_id: scId }).then((dataChanged) => {
            // reload data if manage access has changed
            if (dataChanged)
              this.loadScAccess();
          });
        },
        updateUserDetails (userDetails) {
          this.loadedUser.firstName = userDetails.firstName;
          this.loadedUser.lastName = userDetails.lastName;
          this.loadedUser.email = userDetails.email;
        },
        loadUser () {
          this.isUserLoading = true;
          $.ajax({
            type: 'GET', url: '/main/users/ajax_get_user_v2',
            data: { workspace_id: this.mainMxCurrentWorkshopId, user_id: this.userId }
          }).done((data) => {
            //console.log(data);
            if (data.status === 'error')
              this.invalidUser = true;
            else
              this.loadedUser = data;
            this.isUserLoading = false;

            this.loadScAccess();

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading user');
          });
        },
        determineReporterViewerAccess () {
          this.isUserLoading = true;
          $.ajax({
            type: 'GET', url: '/main/analytics/ajax_get_reporter_viewer_access',
            data: { workspace_id: this.mainMxCurrentWorkshopId, userId: this.userId }
          }).done((data) => {
            if (data.reporter_viewer_access && data.reporter_viewer_access === true) {
              this.reporterViewerAccess = true;
            }
            this.isUserLoading = false;
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading user');
            this.isUserLoading = false;
          });
        },
        saveUserRole (user, roleCode) {
          $.ajax({
            type: 'POST', url: '/main/users/ajax_update_user_role_v2',
            data: { workspace_id: this.mainMxCurrentWorkshopId, user_id: user.id, role_code: roleCode }
          }).done((data) => {
            if (data.status === 'success') {
              ScNotification.growlSuccessMsg(data.details);
              this.loadedUser.roleCode = roleCode;
            }
            else if (data.status === 'error')
              ScNotification.growlErrMsg(data.details);
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'saving user role');
          });
        },
        loadScAccess () {
          this.isAccessLoading = true;
          this.loadedScs.splice(0);
          $.ajax({
            type: 'GET', url: '/main/users/ajax_user_showcases_list',
            data: {
              workspace_id: this.mainMxCurrentWorkshopId, user_id: this.userId,
              pres_action: this.loadedUser.roleCode === 'r:editor' ? 'a:edit_pres' : 'a:view_pres'
            }
          }).done((data) => {
            //console.log(data);
            if (data.status === 'ok')
              this.loadedScs.push(...data.user_showcases);

          }).always(() => {
            this.isAccessLoading = false;
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading presentation access list');
          });
        },
        thumbDimsStyleObject (thumbUrl) {
          let maxWidth = 50; // define max-width for thumbs
          let thumb = ScCommonUtil.getThumbDimensionsFromUrl(thumbUrl, maxWidth, 50);
          let scaleFactor = (maxWidth / thumb.w);

          // landscape = scale thumb height && portrait = original thumb height
          let calcHeight = (thumb.w < maxWidth) ? thumb.h : thumb.h * scaleFactor;
          let calcWidth = (maxWidth < thumb.w) ? maxWidth : thumb.w ;

          return {
            height: calcHeight + 'px',
            width: calcWidth + 'px'
          }
        }
      }
    }
</script>
